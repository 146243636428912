<template>
  <div class="organizations-list">
    <PageHeader
      title="Cabinets"
      v-model="query"
      :has-search="true"
      :has-actions="true"
      :has-back="true"
      @back="goBack"
    >
      <md-button
        class="md-primary md-raised"
        @click="$router.push('/admin/organizations/create')"
      >
        Créer un cabinet
      </md-button>
    </PageHeader>

    <div class="organizations-list-content page-layout">
      <!-- La liste des entreprises -->
      <sortable-list
        title="Cabinets"
        link="/admin/organizations/view/"
        defaultSort="created_at"
        defaultSortType="asc"
        :items="filter"
        :parameters="listParameters"
        :hasPagination="true"
      >
      </sortable-list>
    </div>
  </div>
</template>

<script>
import organizationsModel from "../../../model/organizations";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      query: "",
      data: [],
      listParameters: [
        {
          name: "Nom",
          key: "name",
        },
        {
          name: "Date de création",
          key: "created_at",
          format: this.$$filters.formatTimestamp,
        },
      ],
    };
  },
  methods: {
    goBack() {
      window.history.back();
    },
  },
  mounted() {
    organizationsModel.getAll().then(data => {
      this.data = data;
    });
  },
  computed: {
    filter() {
      if (this.query === "") {
        return this.data;
      } else {
        let q = this.query.toLowerCase();
        return this.data.filter(item => {
          return item.name.toLowerCase().indexOf(q) > -1;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.organizations-list {
  .debug-log {
    max-height: 200px;
    overflow-y: scroll;
  }
}
</style>
